<template>
				
	<!-- Projects Table Card -->
	<a-card :bordered="false" class="header-solid h-full" :bodyStyle="{padding: 0,}">
		<template #title>
			<a-row type="flex" align="middle">
				<a-col :span="24" :md="12">
					<h6>Connections</h6>			
				</a-col>
			</a-row>
		</template>
		<a-table :columns="columns" :data-source="data" :pagination="false">

			<template slot="connectionname" slot-scope="connectionname">
				<h6 class="m-0">
					<img :src="connectionname.logo" width="25" class="mr-10">
					{{ connectionname.name }}
				</h6>
			</template>

			<template slot="apiload" slot-scope="apiload">
				<span class="font-bold text-muted text-sm">{{ apiload.label ? apiload.label : apiload }}</span>
				<a-progress :percent="apiload.value ? apiload.value : apiload" :show-info="false" size="small" :status="apiload.status ? apiload.status : 'normal'" />
			</template>

		</a-table>

	</a-card>
	<!-- / Projects Table Card -->

</template>

<script>

	export default ({
		props: {
			data: {
				type: Array,
				default: () => [],
			},
			columns: {
				type: Array,
				default: () => [],
			},
		},
		data() {
			return {

				// Active button for the "Projects" table's card header radio button group.
				projectHeaderBtns: 'all',
			}
		},
	})

</script>