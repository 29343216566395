<template>
  <!-- Full-Carousel Card -->
  <a-card :bordered="true" body-style="padding: 0; border-radius: 15px; overflow: hidden; height: 100%; position: relative;">
    <a-carousel ref="carousel" dots="false" style="width: 100%; height: 100%;">

      <!-- Check if the user has a Pro subscription -->
      <div v-if="isPro" v-for="(project, index) in projects" :key="project.id" class="carousel-slide">
        <!-- Blurred Background Cover Image -->
        <div class="background-image" :style="{ backgroundImage: 'url(' + project.cover + ')' }"></div>

        <!-- Content Overlay -->
        <div class="overlay-content">
          <!-- Title at Top Left -->
          <h4 class="project-title">{{ project.title }}</h4>

          <!-- Main Content with Small Cover Image and Description -->
          <div class="content-wrapper">
            <img :src="project.cover" :alt="project.title" class="small-cover-image" />
            <div class="description">
              <p>{{ project.content }}</p>
            </div>
          </div>

          <!-- Additional Info for Calls and Notifications -->
          <div class="additional-info">
            <p>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-lightning-charge-fill" viewBox="0 0 16 16">
                <path d="M11.251.068a.5.5 0 0 1 .227.58L9.677 6.5H13a.5.5 0 0 1 .364.843l-8 8.5a.5.5 0 0 1-.842-.49L6.323 9.5H3a.5.5 0 0 1-.364-.843l8-8.5a.5.5 0 0 1 .615-.09z"/>
              </svg>
              <strong> Calls:</strong> {{ project.usedCalls }}/{{ project.maxCalls }}
              <span style="color: #188ffd;">({{ (project.usedCalls / project.maxCalls * 100).toFixed(1) }}%)</span>
            </p>
            <p>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bell-fill" viewBox="0 0 16 16">
                <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2m.995-14.901a1 1 0 1 0-1.99 0A5 5 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901"/>
              </svg>
              <strong> Notifications:</strong> <span style="color: #188efb;">{{ project.notifications }}</span>
            </p>
          </div>
        </div>
      </div>

      <!-- Fallback for Non-Pro Users -->
      <div v-else class="carousel-slide">
        <!-- Fallback Cover Image -->
        <div class="background-image" :style="{ backgroundImage: 'url(images/info-card-2.jpg)' }"></div>

        <!-- Centered Message for Non-Pro Users -->
        <div class="fallback-message">
          <p>Wat jammer joh, skere hond! Had nou maar geupgrade naar Pro.</p>
        </div>
      </div>
    </a-carousel>

    <!-- Navigation Buttons -->
    <div v-if="isPro" class="carousel-navigation">
      <button @click="prev" class="carousel-btn left-btn">‹</button>
      <button @click="next" class="carousel-btn right-btn">›</button>
    </div>

    <!-- Slide Index Indicator -->
    <div v-if="isPro" class="carousel-index">
      {{ currentIndex + 1 }} / {{ projects.length }}
    </div>
  </a-card>
</template>

<script>
export default {
  data() {
    return {
      isPro: true,
      projects: [
        {
          id: 1,
          title: "Modern",
          content: "As Uber works through a huge amount of internal management turmoil.",
          cover: "images/home-decor-3.jpeg",
          maxCalls: 500,
          usedCalls: 70,
          notifications: Math.floor(Math.random() * 100) + 1
        },
        {
          id: 2,
          title: "Scandinavian",
          content: "Music is something that every person has his or her own specific opinion about.",
          cover: "images/home-decor-2.jpeg",
          maxCalls: 500,
          usedCalls: 150,
          notifications: Math.floor(Math.random() * 100) + 1
        },
        {
          id: 3,
          title: "Minimalist",
          content: "Different people have different taste, and various types of music, Zimbali Resort.",
          cover: "images/home-decor-1.jpeg",
          maxCalls: 500,
          usedCalls: 320,
          notifications: Math.floor(Math.random() * 100) + 1
        },
      ],
      currentIndex: 0,
      intervalId: null,
    };
  },
  mounted() {
    this.startAutoPlay();
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
  methods: {
    next() {
      this.$refs.carousel.next();
      this.updateIndex(1);
    },
    prev() {
      this.$refs.carousel.prev();
      this.updateIndex(-1);
    },
    updateIndex(direction) {
      this.currentIndex = (this.currentIndex + direction + this.projects.length) % this.projects.length;
    },
    startAutoPlay() {
      this.intervalId = setInterval(() => {
        this.next();
      }, 10000);
    },
  },
};
</script>

<style scoped>
.carousel-slide {
  position: relative;
  width: 100%;
  height: 100%;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  filter: blur(8px) brightness(0.5); /* Blur and darken the image */
}

.overlay-content {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  color: white;
}

.project-title {
  color: white;
  font-size: 1.2em;
  margin: 0;
}

.content-wrapper {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.small-cover-image {
  width: 80px; /* Set small size for the cover image */
  height: 80px;
  object-fit: cover;
  margin-right: 15px;
  border-radius: 10px;
}

.description {
  flex: 1;
  font-size: 1em;
}

.additional-info {
  display: flex;
  justify-content: space-around;
  padding-top: 10px;
}

.additional-info p {
  flex: 1;
  padding: 8px;
  margin: 0 5px;
  color: white;
  text-align: center;
  font-weight: bold;
}

.carousel-navigation {
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
  padding: 0 20px;
}

.carousel-btn {
  background: rgba(0, 0, 0, 0);
  border: none;
  color: white;
  font-size: 1.5em;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.carousel-btn:hover {
  background: rgba(0, 0, 0, 0.2);
}

.carousel-index {
  text-align: center;
  color: white;
  font-size: 1em;
  position: absolute;
  bottom: 15px;
  width: 100%;
  font-weight: bold;
}

.fallback-message {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: white;
  font-size: 1.5em;
  text-align: center;
}
</style>
