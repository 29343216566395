<template>

	<a-card :bordered="false" class="dashboard-bar-line header-solid">
		<template #title>
			<h6>Api calls September</h6>			
		</template>
		<template #extra>
			<a-badge color="primary" class="badge-dot-primary" text="Traffic" />
		</template>
		<chart-line :height="310" :data="lineChartData"></chart-line>
	</a-card>

</template>

<script>

	// Bar chart for "Active Users" card.
import ChartLine from '../Charts/ChartLine';

export default {
  components: {
    ChartLine,
  },
  data() {
    return {
      // Data for line chart.
      lineChartData: {
        labels: ["Week 36", "Week 37", "Week 38", "Week 39"],
        datasets: [{
          label: "Api calls",
          tension: 0.5, // Maak de lijn gladder
          borderWidth: 3,
          borderColor: "#0856E7",
          backgroundColor: "rgba(8, 86, 231, 0.2)", // Lichte blauwe vulling onder de lijn
          pointRadius: 0, // Geen punten op de lijn
          fill: true, // Achtergrondkleur vullen
          data: [0, 2892, 4890, 6120],
        }],
      },
    };
  },
};


</script>