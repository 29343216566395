<!-- 
	This is the dashboard page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

<template>
	<div>
		<!-- Counter Widgets -->
		<a-row :gutter="24">
			<a-col :span="24" :lg="12" :xl="6" class="mb-24" v-for="(stat, index) in stats" :key="index">
				<!-- Widget 1 Card -->
				<WidgetCounter
					:title="stat.title"
					:value="stat.value"
					:prefix="stat.prefix"
					:suffix="stat.suffix"
					:icon="stat.icon"
					:status="stat.status"
				></WidgetCounter>
				<!-- / Widget 1 Card -->
			</a-col>
		</a-row>
		<!-- / Counter Widgets -->

		<!-- Charts -->
		<a-row :gutter="24" type="flex" align="stretch">
			<a-col :span="24" :lg="12" class="mb-24">

				<!-- Active Users Card -->
				<CardBarChart></CardBarChart>
				<!-- Active Users Card -->

			</a-col>
			<a-col :span="24" :lg="12" class="mb-24">
				
				<!-- Sales Overview Card -->
				<CardLineChart ></CardLineChart>
				<!-- / Sales Overview Card -->

			</a-col>
		</a-row>
		<!-- / Charts -->

		<!-- Table & Timeline -->
		<a-row :gutter="24" type="flex" align="stretch">
			<!-- Table -->
			<a-col :span="24" :lg="16" class="mb-24">
				
				<!-- Projects Table Card -->
				<CardProjectTable
					:data="tableData"
					:columns="tableColumns"
				></CardProjectTable>
				<!-- / Projects Table Card -->
				
			</a-col>
			<!-- / Table -->

			<!-- Timeline -->
			<a-col :span="24" :lg="8" class="mb-24">

				<!-- Orders History Timeline Card -->
				<CardOrderHistory></CardOrderHistory>
				<!-- / Orders History Timeline Card -->

			</a-col>
			<!-- / Timeline -->
		</a-row>
		<!-- / Table & Timeline -->

	</div>
</template>

<script>

	// Bar chart for "Active Users" card.
	import CardBarChart from '../components/Cards/CardBarChart' ;

	// Line chart for "Sales Overview" card.
	import CardLineChart from '../components/Cards/CardLineChart' ;

	// Counter Widgets
	import WidgetCounter from '../components/Widgets/WidgetCounter' ;

	// "Projects" table component.
	import CardProjectTable from '../components/Cards/CardProjectTable' ;

	// Order History card component.
	import CardOrderHistory from '../components/Cards/CardOrderHistory' ;

	// "Projects" table list of columns and their properties.
	const tableColumns = [
		{
			title: 'Connection Name',
			dataIndex: 'connectionname',
			scopedSlots: { customRender: 'connectionname' },
			width: 300,
		},
		{
			title: 'Type',
			dataIndex: 'type',
			scopedSlots: { customRender: 'type' },
		},
		{
			title: 'Status',
			dataIndex: 'status',
			class: 'font-bold text-muted text-sm',
		},
		{
			title: 'Api Load',
			scopedSlots: { customRender: 'apiload' },
			dataIndex: 'apiload',
		},
	];

	// Counter Widgets stats
	const stats = [
		{
			title: "Connections",
			value: 3+ "/" + 5,
			prefix: "",
			suffix: "",
			icon: `
					<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-plug-fill" viewBox="0 0 16 16">
						<path d="M6 0a.5.5 0 0 1 .5.5V3h3V.5a.5.5 0 0 1 1 0V3h1a.5.5 0 0 1 .5.5v3A3.5 3.5 0 0 1 8.5 10c-.002.434-.01.845-.04 1.22-.041.514-.126 1.003-.317 1.424a2.08 2.08 0 0 1-.97 1.028C6.725 13.9 6.169 14 5.5 14c-.998 0-1.61.33-1.974.718A1.92 1.92 0 0 0 3 16H2c0-.616.232-1.367.797-1.968C3.374 13.42 4.261 13 5.5 13c.581 0 .962-.088 1.218-.219.241-.123.4-.3.514-.55.121-.266.193-.621.23-1.09.027-.34.035-.718.037-1.141A3.5 3.5 0 0 1 4 6.5v-3a.5.5 0 0 1 .5-.5h1V.5A.5.5 0 0 1 6 0"/>
					</svg>`,
		},
		{
			title: "Calls today",
			value: 50,
			icon: `
					<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-lightning-charge-fill" viewBox="0 0 16 16">
						<path d="M11.251.068a.5.5 0 0 1 .227.58L9.677 6.5H13a.5.5 0 0 1 .364.843l-8 8.5a.5.5 0 0 1-.842-.49L6.323 9.5H3a.5.5 0 0 1-.364-.843l8-8.5a.5.5 0 0 1 .615-.09z"/>
					</svg>`,
		},
		{
			title: "Errors",
			value: 1,
			status: "danger",
			suffix: "",
			icon: `
					<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-exclamation-triangle-fill" viewBox="0 0 16 16">
  <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5m.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2"/>
</svg>`,
		},
		{
			title: "Remaining calls",
			value: 950 + "/" + 1000,
			icon: `
					<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-lightning-charge-fill" viewBox="0 0 16 16">
						<path d="M11.251.068a.5.5 0 0 1 .227.58L9.677 6.5H13a.5.5 0 0 1 .364.843l-8 8.5a.5.5 0 0 1-.842-.49L6.323 9.5H3a.5.5 0 0 1-.364-.843l8-8.5a.5.5 0 0 1 .615-.09z"/>
					</svg>`,
		},
	] ;

	// "Projects" table list of rows and their properties.
	const tableData = [
		{
			key: '1',
			connectionname: {
				name: 'Haven Link',
				logo: 'images/logos/logo-shopify.svg',
			},
			type: 'Post',
			status: 'Active',
			apiload: 60,
		},
		{
			key: '2',
			connectionname: {
				name: 'Add Progress Track',
				logo: 'images/logos/logo-atlassian.svg',
			},
			type: 'Get',
			status: 'Inactive',
			apiload: 10,
		},
		{
			key: '3',
			connectionname: {
				name: 'Errors notification',
				logo: 'images/logos/logo-slack.svg',
			},
			type: 'Post',
			status: 'Active',
			apiload: 100,
		},
		{
			key: '4',
			connectionname: {
				name: 'Monthly streamers view',
				logo: 'images/logos/logo-spotify.svg',
			},
			type: 'Get',
			status: 'Active',
			apiload: 100,
		},
		{
			key: '5',
			connectionname: {
				name: 'Order stock',
				logo: 'images/logos/logo-jira.svg',
			},
			type: 'Get',
			status: 'Active',
			apiload: 25,
		},
		{
			key: '6',
			connectionname: {
				name: 'Show leads',
				logo: 'images/logos/logo-invision.svg',
			},
			type: 'Get',
			status: 'Active',
			apiload: 40,
		},
	];

	export default ({
		components: {
			CardBarChart,
			CardLineChart,
			WidgetCounter,
			CardProjectTable,
			CardOrderHistory,
		},
		data() {
			return {

				// Associating table data with its corresponding property.
				tableData,

				// Associating table columns with its corresponding property.
				tableColumns,

				// Counter Widgets Stats
				stats,
			}
		},
	})

</script>

<style lang="scss">
</style>